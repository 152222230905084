import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useLogos } from '@mwl/core-lib';
import notFoundError from '@public/assets/error/images/404.webp';
import serverError from '@public/assets/error/images/500.webp';
import { Button } from '@/components/Button/Button';
import { Image } from '@/components/Image/Image';
import { routes } from '@/constants';
import type { ErrorLayoutProps } from '@/Layouts/Error/ErrorLayout.types';
import { errorPageObject } from '@/utils';
import styles from './ErrorLayout.module.scss';
const ErrorLayout: FC<ErrorLayoutProps> = ({
  code,
  message
}) => {
  const {
    t
  } = useTranslation('error');
  const {
    main
  } = useLogos();
  return <div className={styles.root} data-sentry-component="ErrorLayout" data-sentry-source-file="ErrorLayout.tsx">
      <div className={styles.logo}>
        <Image src={main} priority fill style={{
        objectFit: 'contain'
      }} alt="Banzai" data-sentry-element="Image" data-sentry-source-file="ErrorLayout.tsx" />
      </div>

      <div className={styles.image}>
        {code === 404 && <Image src={notFoundError} priority alt="Not found" />}
        {code === 500 && <Image src={serverError} priority alt="Internal server error" />}
      </div>

      <div className={styles.action}>
        <p className={styles.text} {...errorPageObject.text.nodeProps}>
          {message}
        </p>
        {code === 404 && <Button as="external" className={styles.button} href={routes.home} color="red" size="md" fontWeight="bold" fontSize="xl" target="_self" rounded {...errorPageObject.button.nodeProps}>
            {t('back_home', 'Back home')}
          </Button>}
      </div>
    </div>;
};
export { ErrorLayout };